/**
 *List  外部传入列表  外部定义方法getShopId 选择时出发自定义事件
 */
<template>
  <span>
      <el-select  @clear="clearInfo" :clearable="clearable" v-model="value" @input="$emit('input', $event)" :placeholder="placeholder">
          <el-option-group
            v-for="group in list"
            :key="group.id"
            :label="group.name">
                <el-option
                    v-for="item in group.marketPlaceTree"
                    :key="item.assembleId"
                    :label="group.name+'-'+item.name"
                    :value="item.assembleId">
                    <p @click="getID(item.assembleId,group.name+'-'+item.name,item.currency, item.profileId)">{{item.name}}</p>
                </el-option>
            </el-option-group>
      </el-select>
  </span>
</template>

<script>
export default {
    props:{
        list:Array,
        placeholder:{
            default:'请选择店铺',
            type:String
        },
        defaultData:String,
        clearable:{
            default:false,
            type:Boolean
        }
    },
    data(){
        return{
            shopName:'',
            value:''
        }
    },
    mounted(){
        let arr = []
        this.list.forEach(item=>{
            let shopId = item.id
            item.marketPlaceTree.forEach(i=>{
                i.assembleId=shopId+'-'+i.id
                if(i.isSelected==1){
                    this.value=shopId+'-'+i.id
                    this.getID(this.value,item.name+'-'+i.name,i.currency, i.profileId)
                }
            })
        })
    },
    beforeUpdate(){
        let arr = []
        this.list.forEach(item=>{
            let shopId = item.id
            item.marketPlaceTree.forEach(i=>{
                i.assembleId=shopId+'-'+i.id
            })
        })
    },
    methods:{
        getID(assembleId,name,currency,profileId){
            const shopId = assembleId.substring(0,assembleId.indexOf('-'))
            const siteId = assembleId.substring(assembleId.indexOf('-')+1)
            this.$emit('getShopId',shopId,siteId,name,currency,profileId)
        },
        clearInfo(){
            console.log(1);
            this.$emit('getShopId','','')
        }
    }
}
</script>

<style scoped lang='scss'>
::v-deep .el-select-group__title{
    font-size:12px;
    margin-left: -9px;
    transform: scale(0.86);
}
::v-deep .el-select-dropdown__item{
    margin-left: 14px;
}
</style>